import React from "react";
import Layout from "../../components/layout";
import Breadcrumb from "../../components/breadcrumb";
import Brand from "../../components/product-grid/brand";
import SearchGrid from "../../components/search/search-grid";
import LoadingSpinner from "../../components/loading-spinner";
import algoliasearch from "algoliasearch";
import { Grid } from "@material-ui/core";
import { GQLComponent } from "../../types";
import GlobalConstants, {
  Algolia,
  UsaMade as Constants,
} from "../../helpers/constants";

/* Returns an object representing the product path. Used to create the breadcrumb. */
const GetPathObj = () => {
  const pathObj = {};
  pathObj[GlobalConstants.Home] = "/";
  pathObj[GlobalConstants.Products] = "/products";
  pathObj[GlobalConstants.UsaMade] = "/products/usa-made";

  return pathObj;
};

const UsaMade = (): JSX.Element => {
  const PathObj = GetPathObj();
  const [State, SetState] = React.useState({ hits: [], loading: true });
  const RunOnce = true;

  /* Fetches products on page load. This must be this way since
    "USA made" is stored on Algolia */
  React.useEffect(() => {
    const Client = algoliasearch(Algolia.AppId, Algolia.ApiKey);
    const Index = Client.initIndex(Algolia.IndexName);

    Index.search(Constants.UsaMadeText).then(({ hits }) => {
      SetState({ hits: hits, loading: false });
    });
  }, [RunOnce]);

  return (
    <Layout>
      <Breadcrumb pathObj={PathObj} />
      <Grid item xs={12} sm={4} md={3}>
        <Brand
          brand={Constants.UsaMadeText}
          logo={{ url: "/usa-made.png", altText: "USA Made" }}
          description={
            {
              content: {
                html: [Constants.DescriptionHtml],
              },
            } as GQLComponent
          }
        />
      </Grid>
      <SearchGrid results={State.hits} loading={State.loading} />
      <LoadingSpinner open={State.loading} />
    </Layout>
  );
};

export default UsaMade;
